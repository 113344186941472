.dashboard__div {
    width: 100%;
    height: 100%;
}

.dashboard_subtitle {
    font-size: 16px;
    font-family: Amazon Ember;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.15px;
}

.dashboard_title {
    font-size: 20px;
    font-family: Amazon Ember;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.15px;
}

.dashboard__button__icon_div {
    height: 62px;
    width: 62px;
    border-radius: 100px;
    position: relative;
}

.dashboard__button_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dashboard__new_subscriber_icon_div {
    background-color: #E6F1FF;
}

.dashboard__event_source_icon_div {
    background-color: #FFE6ED;
}

.dashboard__tenant_button_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.dashboard__tenant_button {
    height: 42px;
    width: 187px;
    background: #0070F0;
    border-radius: 4px;
    font-family: 'Amazon Ember';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    box-shadow: none !important;
    letter-spacing: 0.46px;
}

.dashboard__icon_buttons__row {
    width: calc(100% - 15px) !important;
    margin-left: auto;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.dashboard__icon_button_div {
    height: 110px;
    width: 312px;
    position: relative;
}

.dashboard__icon_button_div__wrapper {
    position: absolute;
    width: max-content;
    top: 50%;
    transform: translateY(-50%);
}

.alerts__div {
    width: max-content;
    position: absolute;
    right: 50px;
    bottom: 50px;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.subscriptions_subscribers_table__div {
    width: 100%;
    margin-top: 24px;
}

.subscriptions_subscribers_table__paper{
    width: 1376px;
    margin-left: auto;
    margin-right: auto;
    height: 75px;
    box-shadow: none !important;
}

.subscriptions_subscribers_table__tab{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: end;
}

.subscriptions_subscribers_table__tab__item{
    padding-left: 16px !important;
    padding-right: 16px !important;
    text-transform: capitalize !important;
    border-radius: 0px !important;
}

.subscriptions_subscribers_table__wrapper{
    margin-top: 2px;
}

.subscriptions_subscribers_table__tab__grid{
    justify-content: space-between;
    align-items: end;
    height: 100%;
}

.filtering__div{
    width: 263px;
    margin-right: 14px !important;
    margin-bottom: 23px !important;
}