.table_type{
    text-transform: capitalize;
}

.item_name{
    color: #0071B3 !important;
    text-decoration: none !important; 
}

.esc__table_header__description {
    width: 735.119px;
}

.esc__table__subscriptions {
    width: 170px;
}

.empty_items__div{
    margin-top: 16px !important;
    margin-left: 16px !important;
}

.esc__table_description{
    padding-left: 35px !important;
}

.status__col{
    padding-left: 10px !important;
}

.status_chip{
    text-transform: capitalize !important;
}

.esc__table__name {
    width: 430px;
    padding-left: 12px !important;
}