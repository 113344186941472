.table_type{
    text-transform: capitalize;
}

.item_name{
    color: #0071B3 !important;
    text-decoration: none !important; 
}

.esc__table__name {
    width: 430px;
    padding-left: 24px !important;
}

.esc__table_header__description {
    width: 735.119px;
}

.esc__table__subscriptions__text{
    padding-left: 20px !important;
}