.table_container{
    width: 1366px;
    padding: 0px !important;
    margin: 0px !important;
}

.esc__table_header__row,
.esc__table_head,
.esc__table,
.esc__table_cell {
    border-radius: 0px !important;
}

.esc__table__cell{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.esc__table_header,
.esc__table_header__title {
    color: #000000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.17px;
}

.esc__table__name {
    width: 371px;
    padding-left: 24px !important;
}

.esc__table_header__row__div {
    display: flex;
    flex-direction: row;
    position: relative;
}

.esc__table_header__description {
    width: 647.119px;
}

.esc__table_header__divider {
    width: 2px;
    height: 14px;
    background-color: #D9D9D9;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.esc__table_header__title {
    padding-left: 18px;
}

.esc__table__row {
    background-color: white;
    height: 52px !important;
}

.esc__table__cell {
    color: #000000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
}

.esc__table__name_row {
    display: flex;
    flex-direction: row;
    column-gap: 18px;
    position: relative;
    align-items: center !important;
}

.esc__table__name_pill {
    height: 32px;
    width: max-content;
    min-width: 42px;
    background-color: #FFF5E6;
    border-radius: 100px;
    position: relative;
}

.esc__table__name_pill__span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #CC7A00;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Amazon Ember";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
}

.esc__table_description {
    padding-left: 35px !important;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 252px !important;
    height: 35px;
    border-radius: 4px;
    background-color: #0070f0;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    line-height: 280%;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
}

.button:hover {
    background-color: #E6F1FF;
    color: #0070f0;
}

.button__span {
    font-family: "Amazon Ember";
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 22px;
    letter-spacing: 0.46px;
}

.esc__table_action {
    width: 280px;
}