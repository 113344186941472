.esc_header__div,
.esc_header__action_div,
.view_options__div {
    display: flex;
    flex-direction: row;
}

.esc_header__div {
    align-items: center;
}

.esc_header__action_div {
    margin-top: auto;
}

.filtering__div {
    width: 211px;
    margin-right: 16px;
    align-items: center;
}

.form_text_field {
    width: 100%;
    min-width: 100%;
}

.esc_title__div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 15px;
}

.arrow_back_icon__div {
    height: 100%;
    position: relative;
}

.arrow_back_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.view_options__div {
    column-gap: 8px;
}

.icon_button {
    width: 24px;
    height: 24px;
}

.esc_options__icon {
    height: 24px;
    width: 24px;
}

.grid {
    margin-top: 24px;
}

.pagination {
    margin-top: 24px;
}

.alerts__div {
    width: max-content;
    position: absolute;
    right: 50px;
    bottom: 50px;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.esc_list_view__div {
    width: 100%;
    margin-left: 5px;
}

.subtitle,
.description {
    padding-top: 8px;
    padding-left: 15px;
    font-style: normal !important;
    font-weight: 400 !important;
}

.subtitle {
    color: #808080;
    font-size: 16px !important;
}

.esc_subtitle__div {
    width: 100%;
}

.description {
    color: #000;
    font-size: 14px !important;
}

.subscriber_management__table {
    margin-top: 8px;
}

.action_bar__grid {
    justify-content: space-between;
}

.action_bar__div {
    margin-top: 8px;
    margin-right: 16px;
    align-items: center;
    height: 40px;
}

.action_bar__button {
    text-transform: capitalize !important;
    color: #0070F0;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}