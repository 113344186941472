.main {
    width: 100%;
    height: 100%;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: max-content;
    min-height: 100vh;
}

.left_column {
    height: 100%;
    width: 1440px !important;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.left_column__wrapper{
    margin-left: 32px;
    margin-right: 32px;
}