.tenants__title{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
    margin-top: 0px !important;
    letter-spacing: 0.15px;
}

.tenants__section_title{
    color: #808080;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.15px;
}

.tenants__grid{
    margin-top: 24px;
}

.tenant_card__div{
    width: 333px;
}