* {
  font-family: 'Amazon Ember' !important;
}

body{
  margin: 0px !important;
  background-color: #F7F7F5;
}

.w100 {
  width: 100%;
}

.GFT--primary-color {
  color: #0070F0;
}

.GFT--action-color {
  color: #808080;
}

.GFT--success-color {
  color: #1F7A1F;
}

.GFT--success-light-color {
  color: #EBFAEB;
}

.GFT--success-dark-color {
  color: #196619;
}

.GFT--error-color {
  color: #E60000;
}

.GFT--error-light-color {
  color: #FFE6E6;
}

.GFT--error-dark-color {
  color: #800000;
}

.link__no_decoration {
  text-decoration: none;
}

/* To avoid MUI & Chrome autofill color (blue shadow) effect */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}
