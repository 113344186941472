.modal__table {
    padding-right: 12px !important;
    padding-left: 12px !important;
    padding-top: 0 !important;
    max-height: 600px;
    overflow: auto;
}

.modal__title {
    padding-top: 24px !important;
    padding-bottom: 12px !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}

.modal__subtitle {
    padding-left: 24px !important;
    padding-top: 0 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.modal__table_headers {
    font-size: 14px !important;
    font-weight: 500 !important;
    padding-left: 12px !important;
}

.modal__table_body {
    font-size: 14px !important;
    font-weight: 400 !important;
    max-height: 625px;
}

.modal__row {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 12px !important;
}

.modal__buttons_row {
    display: flex;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    flex-direction: row;
    column-gap: 10px;
    justify-content: end;
    right: 0;
    bottom: 0;
}

.modal__empty {
    padding:20px;
}