.light_button__row{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.light_button{
    background-color: white !important;
    width: 312px;
    height: 110px;
}

.light_button__name{
    line-height: 60px;
    font-style: normal;
    color: #808080;
    font-size: 16px;
    font-weight: 500;
}

.light_button:hover,
.light_button:active,
.light_button:after,
.light_button:focus {
    background-color: white !important;
}