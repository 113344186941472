.card__upper_subtitle {
    color: #757575;
}

.card__title {
    color: #0070F0;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px !important;
    letter-spacing: 0.15px;
}

.card__header_div{
    border: transparent;
    border-bottom: 1px solid #0000001F;
    background-color: transparent;
    width: 100%;
    padding: 15px;
}