.modal__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    /* box-shadow: 24; */
    background-color: white;
    height: 550px;
    width: 510px;
    border-radius: 4px;
}

.modal__title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    margin-top: 16px !important;
    margin-left: 20px !important;
}

.modal__form_div{
    position: relative;
    width: 470px;
    height: calc(100% - 100px);
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}

.modal__form__field_div{
    margin-bottom: 16px;
}

.modal__row{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 20px;
    margin-top: 20px;
}

.modal__form__col__field_div{
    width: calc(50% - 10px);
}

.modal__buttons_row{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: end;
    position: absolute;
    right: 0;
    bottom: 0;
}

.modal__form__field_button{
    width: 100%;
    background-color: transparent;
    border: none !important;
    margin: 0;
    padding: 0;
}