.card__size {
    width: 333px;
    height: 272px;
    display: block;
    position: relative;
    background-color: white;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 301px;
    height: 36px;
    border-radius: 4px;
    background-color: #0070f0;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    line-height: 280%;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
}

.button:hover {
    background-color: #E6F1FF;
    color: #0070f0;
}

.description {
    padding: 16px;
    padding-top: 8px;
    padding-bottom: 14px;
    font-size: 13px;
    letter-spacing: 0.16px;
}

.card_actions{
    position: absolute;
    left: 50%;
    bottom: 8px;
    transform: translateX(-50%);
    text-align: center;
}